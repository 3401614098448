import React from 'react'
import Navigation from './Navigation'

const Header = props => {
    return (
        <>
        <header>
            <h1>{props.translations.slogan}</h1>
            <Navigation showPage={props.showPage} translations={props.translations} page={props.page} />
            <p>Live your life 4 half the price has come about because I believe that some of the best things in life are free, but for everything else let's try and live our best lives 4 half the price! (*where possible!)</p>
        </header>
        </>
    )
}

export default Header