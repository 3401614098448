import React from 'react'
import PostContainer from '../components/Posts/PostContainer'

const Archive = props => {
    return (
        <>
            <PostContainer postData={props.postData} showPost={props.showPost} page={props.page} />
        </>
    )
}

export default Archive