import Header from './components/atoms/Header'
import * as Translations from './resources/index'
import './App.css';
import React, {Component} from 'react'
import axios from 'axios'
import Util from './Util'
import About from './components/About';
import GetInTouch from './components/GetInTouch';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import PostModal from './components/Posts/PostModal';
import PostContainer from './components/Posts/PostContainer';
import Archive from './components/Archive';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
const Auth = new Util();
const api = Auth.api

class App extends Component{
  constructor(props){
    super(props)
    this.state = {
      page: '',
      open: false
    }
  }

  componentDidMount(){
    axios.get(`${api}posts?_embed`)
    .then(res => {
      res.data.map((item, index) => item.postId = index )
      this.setState({postData: res.data, postsLength: res.data.length})
    })
    axios.get(`${api}pages?_embed`)
    .then(res => {
      this.setState({pageData: res.data, pagesLength: res.length, homePageContent: res.data[0].content.rendered})
    })
    this.setState({url: window.location.pathname}, () => {
      if (this.state.url.length !== 1){
        axios.get(`${api}posts?slug=`+this.state.url)
        .then(res => {
          this.setState({loadedViaUrl: true}, () => {
            this.showPost(res.data)
          })
          //this.setState({postId: res.data[0].id, open: true, postContent: res.data[0].content.rendered, postTitle: res.data[0].title.rendered})
          
        })
      }
    })
  }

  showPage = (page) => {
    var pageVar
    pageVar = this.state.pageData.filter(function (el) {
      return el.slug === page
    });
    this.setState({page: page, pageContent: pageVar})
  }

  showPost = (post) => {
    if (post === undefined){
      this.setState({open: false}, () => { if (window.location.pathname !== ''){
        window.location.pathname = ''
      }})
      
    } else {
      this.setState({open: true, postContent: post[0].content.rendered, postTitle: post[0].title.rendered, postId: post[0].postId}, () => {
        if (this.state.postId === 0){
          this.setState({nextPostExists: false})
        } else {
          if (!this.state.loadedViaUrl){
            this.doesNextPostExist(this.state.postId - 1)
          }
        }
        if (!this.state.loadedViaUrl){
          this.doesPreviousPostExist(this.state.postId + 1)
        }
        
      })
    }
  }

  showNextPost = (id) => {
    let nextPostData = this.state.postData.filter(function (el) {
      return el.postId === id
    });
    this.setState({postContent: nextPostData[0].content.rendered, postTitle: nextPostData[0].title.rendered, postId: nextPostData[0].postId})
  }

  doesNextPostExist = (id) => {
    var nextPostVar
    nextPostVar = this.state.postData.filter(function (el) {
      return el.postId === id
    });
    if (nextPostVar.length !== 0){
      this.setState({nextPostExists: true, nextPostContent: nextPostVar})
    } else {
      this.setState({nextPostExists: false})
    }
  }

  doesPreviousPostExist = (id) => {
    var prevPostVar
    prevPostVar = this.state.postData.filter(function (el) {
      return el.postId === id
    });
    if (prevPostVar){
      this.setState({previousPostExists: true, prevPostContent: prevPostVar})
    } else {
      this.setState({previousPostExists: false})
    }
  }

  render(){
    return (
      <>
      <Router>
      <div className="App">
        <Header translations={Translations} showPage={this.showPage} page={this.state.page} />
      
        {this.state.page === '' ?
        <>
        <div dangerouslySetInnerHTML={{ __html: this.state.homePageContent}}></div>
        {this.state.postData !== undefined  ?
          <>
          <h2>Check out my recent posts...</h2>
          <PostContainer postData={this.state.postData} showPost={this.showPost} page={this.state.page} />
          </>
          :
          <h1>COMING SOON</h1>
          }
        </>
        :null}
        

        {this.state.page === 'about' ?
        <>
        <About pageContent={this.state.pageContent} />
        </>
        :null}

        {this.state.page === 'archive' ?
        <>
        <Archive postData={this.state.postData} showPost={this.showPost} page={this.state.page} />
        </>
        :null}

        {this.state.page === 'getintouch' ?
        <>
        <GetInTouch />
        </>
        :null}
        
        <Popup open={this.state.open}>
          <PostModal
            postContent={this.state.postContent}
            nextPostExists={this.state.nextPostExists}
            previousPostExists={this.state.previousPostExists}
            postId={this.state.postId}
            postTitle={this.state.postTitle}
            showPost={this.showPost}
            nextPost={this.showNextPost}
            nextPostContent={this.state.nextPostContent}
            postsLength={this.state.postsLength - 1}
            loadedViaUrl={this.state.loadedViaUrl}
          />
        </Popup>
        
      </div>
      </Router>
      
      
      </>
    )
  }
}

export default App;
