export default class Util {
    constructor(){
        this.api = ''
        if (process.env.NODE_ENV === 'development'){
            this.api = process.env.REACT_APP_SECRET_KEY
        }
        if (process.env.NODE_ENV === 'production'){
            this.api = process.env.REACT_APP_SECRET_KEY
        }
    }
}