import React from 'react'
import PostBox from './PostBox'

const PostContainer = props => {
    var linkData
    const showPost = (slug) => {
        linkData = props.postData.filter(function (el) {
            return el.slug === slug
        });
        props.showPost(linkData)
    }
    return (
        <>
        <div className="row">
            {props.postData !== undefined ?
                <>
                {props.page === '' ?
                <>
                    {props.postData.slice(0, 3).map((item, index) => 
                        <PostBox index={index} data={item} allData={props.postData} showPost={showPost} />
                    )}
                </>
                :                    
                <>
                {props.postData.map((item, index) => 
                    <PostBox index={index} data={item} allData={props.postData} showPost={showPost} />
                )}
                </>
                }

                </>
            :null}
        </div>
        </>
    )
}

export default PostContainer