import React from 'react'

const About = props => {
    return (
        <>
        <div dangerouslySetInnerHTML={{ __html: props.pageContent[0].content.rendered }}></div>
        </>
    )
}

export default About