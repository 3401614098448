import React from 'react'

const PostModal = props => {
    return (
        <>
        <div className="modal">
        
          <>
            <span className="close" onClick={() => props.showPost()}>
            &times;
            </span>
            
            <h2>{props.postTitle}</h2>
            <div className="post-content-wrapper" dangerouslySetInnerHTML={{ __html: props.postContent }}></div>
            {!props.loadedViaUrl ?
            <>
            {props.postId !== 0 ?
              <button className="next" next-id={props.postId - 1} onClick={() => props.nextPost(props.postId - 1, props.nextPostContent)}>Next</button>
              :null}
              {props.postId !== props.postsLength ?
              <button className="previous" previous-id={props.postId + 1} onClick={() => props.nextPost(props.postId + 1)}>Previous</button>
              :null}
              </>
            :null}
            
            </>
          
        </div>
        </>
    )
}

export default PostModal