import React from 'react'

const PostBox = props => {    
    return (
        <>
        <div className="column" onClick={() => props.showPost(props.data.slug)} data-slug={props.data.slug}>
            <div className="card">
                <h2>{props.data.title.rendered}</h2>
                
                <p dangerouslySetInnerHTML={{ __html: props.data.excerpt.rendered }}></p>
                <h4>10/04/2021</h4>
            </div>
        </div>
        </>
    )
}

export default PostBox