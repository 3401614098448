import React from 'react'

const Navigation = props => {
    return (
        <nav>
          <ul>
            {props.page !== '' ? <li onClick={() => props.showPage('')}>Home</li>:null}
            {props.page !== 'about' ? <li className="one" onClick={() => props.showPage('about', props.page)}>{props.translations.link_about}</li>:null}
            {props.page !== 'archive' ? <li className="two" onClick={() => props.showPage('archive')}>{props.translations.link_archive}</li>:null}
            {props.page !== 'getintouch' ? <li onClick={() => props.showPage('getintouch')}>{props.translations.link_getInTouch}</li>:null}
          </ul>
        </nav>
    )
}

export default Navigation