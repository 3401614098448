import React from 'react'

const GetInTouch = props => {
    return (
        <>
        <div className="about-container">
        <div className="bgimg-1"></div>
        <div className="caption">
            <span className="border">getintouch@liveyourlife4halftheprice.co.uk</span>
        </div>
        </div>
        
        </>
    )
}

export default GetInTouch